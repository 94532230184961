import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    recentlyDeleteDocument:{},
    loading: false
}

export const recentlyDeleteDocumentSlice = createSlice({
    name: 'recentlyDeleteDocument',
    initialState,
    reducers: ({
        recentlyDeleteDocuments: (state) => {
            state.loading = true
        },
        setRecentlyDeleteDocuments: (state, action) => {
            state.loading = false
            state.recentlyDeleteDocument = action.payload
        },
        onErrorStopLoad: (state) => {
            state.loading = false
        }
    }),
})

// Action creators are generated for each case reducer function
export const { recentlyDeleteDocuments, setRecentlyDeleteDocuments,onErrorStopLoad } = recentlyDeleteDocumentSlice.actions

export default recentlyDeleteDocumentSlice.reducer
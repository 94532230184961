import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    loading: false,
};

export const contactUsSlice = createSlice({
    name: "contactUs",
    initialState,
    reducers: {
        addContactUs: (state) => {
            state.loading = true;
        },
        setAddContactUs: (state, action) => {
            state.loading = false;
        },
        onErrorStopLoad: (state) => {
            state.loading = false;
        },
    },
});

// Action creators are generated for each case reducer function
export const {
    addContactUs,
    setAddContactUs,
    onErrorStopLoad,
} = contactUsSlice.actions;

export default contactUsSlice.reducer;

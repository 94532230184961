import { all, call, put, takeLatest } from 'redux-saga/effects'
import { ApiClient } from '../../../utilities/api';
import ApiPath from '../../../constants/apiPath';
import { toast } from 'react-toastify';
import { onErrorStopLoad, setDiversityCertification, setDiversityOwned, setGetDiversityCertification } from '../../slices/certification';

// Worker saga will be fired on USER_FETCH_REQUESTED actions

function* DiversityCertification(action) {
    try {
      const resp = yield call(ApiClient.post, action.url = ApiPath.CertificationApiPath.DIVERSITY_CERTIFICATION, action.payload = action.payload);
      if (resp.status) {
        yield put(setDiversityCertification(resp.data.payload));
        yield call(action.payload.cb, action.res = resp)
        // toast.success(action.res.data.msg);'

      }
      else {
        throw resp
      }
    } catch (e) {
      yield put(setDiversityCertification({}));
      yield put(onErrorStopLoad())
      toast.error(e.response.data.msg);
    }
  }

  function* GetDiversityCertification(action) {
    try {
      const resp = yield call(ApiClient.get, action.url = ApiPath.CertificationApiPath.GET_DIVERSITY_CERTIFICATION, action.payload = action.payload);
      if (resp.status) {
        yield put(setGetDiversityCertification(resp.data.payload));
        yield call(action.payload.cb, action.res = resp)
        // toast.success(action.res.data.msg);'

      }
      else {
        throw resp
      }
    } catch (e) {
      yield put(setGetDiversityCertification({}));
      yield put(onErrorStopLoad())
      toast.error(e.response.data.msg);
    }
  }

  function* DiversityOwned(action) {
    try {
      const resp = yield call(ApiClient.get, action.url = ApiPath.CertificationApiPath.DIVERSITY_OWNED, action.payload = action.payload);
      if (resp.status) {
        yield put(setDiversityOwned(resp.data.payload));
        yield call(action.payload.cb, action.res = resp)
        // toast.success(action.res.data.msg);'

      }
      else {
        throw resp
      }
    } catch (e) {
      yield put(setDiversityOwned({}));
      yield put(onErrorStopLoad())
      toast.error(e.response.data.msg);
    }
  }


function* userCertification() {
    yield all([
      takeLatest('certification/diversityCertification', DiversityCertification),
      takeLatest('certification/getDiversityCertification', GetDiversityCertification),
      takeLatest('certification/diversityOwned', DiversityOwned),
    ])
  }
  
  export default userCertification;
import { all, call, put, takeLatest } from 'redux-saga/effects'
import { ApiClient } from '../../../utilities/api';
import ApiPath from '../../../constants/apiPath';
import { toast } from 'react-toastify';
import { setManageUsers, onErrorStopLoad, setGetCompanyUsers, setDeleteManageUser } from '../../slices/manageusers';


// Worker saga will be fired on USER_FETCH_REQUESTED actions
function* addManageUsers(action) {
  try {
    const resp = yield call(ApiClient.post, action.url = ApiPath.ManageUsers.ADD_MANAGE_USER, action.payload = action.payload);
    if (resp.status) {
      yield put(setManageUsers(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setManageUsers({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}

function* deletePerUser(action) {
  try {
    const resp = yield call(ApiClient.delete, action.url = `${ApiPath.ManageUsers.DELETE_MANAGE_USER}/${action.payload.id}`, action.params = {});
    if (resp.status) {
      yield put(setDeleteManageUser(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setDeleteManageUser({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}

function* getCompanyUsersList(action) {
  try {
    var dataToSend = { ...action.payload }
    delete dataToSend.cb

    const resp = yield call(ApiClient.get, action.url = ApiPath.ManageUsers.GET_MANAGE_USER_LIST, action.params = { params: dataToSend });
    if (resp.status) {
      yield put(setGetCompanyUsers(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setGetCompanyUsers({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}


function* manageUsersSaga() {
  yield all([
    takeLatest('manageusers/manageUsers', addManageUsers),
    takeLatest('manageusers/getCompanyUsers', getCompanyUsersList),
    takeLatest('manageusers/deleteManageUser', deletePerUser),

    ,

  ])
}

export default manageUsersSaga;

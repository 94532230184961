import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  userInfo: {},
  loading: false
}

export const userSlice = createSlice({

  name: 'user',
  initialState,
  reducers: {
    getUserInfo: (state) => {
      state.loading = true
    },
    setUserInfo: (state, action) => {
      state.loading = false
      state.userInfo = action.payload
    },
    putUserInfo: (state) => {
      state.loading = true
    },
    setPutUserInfo: (state, action) => {
      state.loading = false
      state.userInfo = action.payload
    },
    postUserInfo: (state) => {
      state.loading = true
    },
    setPostUserInfo: (state, action) => {
      state.loading = false
      state.userInfo = action.payload
    },
    patchUserInfo: (state) => {
      state.loading = true
    },
    setPatchUserInfo: (state, action) => {
      state.loading = false
      state.userInfo = action.payload
    },
    deleteUserInfo: (state) => {
      state.loading = true
    },
    setDeleteUserInfo: (state, action) => {
      state.loading = false
      state.userInfo = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const { setUserInfo, getUserInfo, putUserInfo, setPutUserInfo, postUserInfo, setPostUserInfo, patchUserInfo, setPatchUserInfo, deleteUserInfo, setDeleteUserInfo } = userSlice.actions

export default userSlice.reducer
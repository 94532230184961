import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    updateArchiveDocument:{},
    archiveDocuments:{},
    loading: false
}

export const archiveDocumentSlice = createSlice({
    name: 'archivedocuments',
    initialState,
    reducers: ({
        updateArchiveDocuments: (state) => {
            state.loading = true
        },
        setUpdateArchiveDocuments: (state, action) => {
            state.loading = false
            state.updateArchiveDocument = action.payload
        },
        getArchiveDocuments: (state) => {
            state.loading = true
        },
        setGetArchiveDocuments: (state, action) => {
            state.loading = false
            state.archiveDocuments = action.payload
        },
        onErrorStopLoad: (state) => {
            state.loading = false
        }
    }),
})

// Action creators are generated for each case reducer function
export const { getArchiveDocuments,setGetArchiveDocuments,updateArchiveDocuments,setUpdateArchiveDocuments,onErrorStopLoad } = archiveDocumentSlice.actions

export default archiveDocumentSlice.reducer
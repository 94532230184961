import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    getMyDocuments:{},
    uploadDocuments : {},
    documentDelete:{},
    loading: false
}

export const documentsSlice = createSlice({
    name: 'documents',
    initialState,
    reducers: ({
        myDocuments: (state) => {
            state.loading = true
        },
        setMyDocuments: (state, action) => {
            state.loading = false
            state.getMyDocuments = action.payload
        },
        deleteDocument: (state) => {
            state.loading = true
        },
        setDeleteDocument: (state, action) => {
            state.loading = false
            state.documentDelete = action.payload
        },
        uploadAllDocuments: (state) => {
            state.loading = true
        },
        setUploadAllDocuments: (state, action) => {
            state.loading = false
            state.uploadDocuments = action.payload
        },
        onErrorStopLoad: (state) => {
            state.loading = false
        }
    }),
})

// Action creators are generated for each case reducer function
export const { myDocuments,setMyDocuments,deleteDocument,setDeleteDocument, uploadAllDocuments, setUploadAllDocuments, onErrorStopLoad } = documentsSlice.actions

export default documentsSlice.reducer
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  manageUsers: {},
  mangageUserList: {},
  loading: false,
};

export const mangeUsersSlice = createSlice({
  name: "manageusers",
  initialState,
  reducers: {
    manageUsers: (state) => {
      state.loading = true;
    },
    setManageUsers: (state, action) => {
      state.loading = false;
      state.manageUsers = action.payload;
    },
    getCompanyUsers: (state) => {
      state.loading = true;
    },
    setGetCompanyUsers : (state, action) => {
      state.loading = false;
      state.mangageUserList = action.payload
    },
    deleteManageUser: (state) => {
      state.loading = true;
    },
    setDeleteManageUser: (state) => {
      state.loading = false
    },
    onErrorStopLoad: (state) => {
      state.loading = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
    manageUsers,
    setManageUsers,
    getCompanyUsers, 
    setGetCompanyUsers,
    deleteManageUser, 
    setDeleteManageUser,
    onErrorStopLoad
} = mangeUsersSlice.actions;

export default mangeUsersSlice.reducer;

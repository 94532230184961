import { all, call, put, takeLatest } from 'redux-saga/effects'
import { ApiClient } from '../../../utilities/api';
import ApiPath from '../../../constants/apiPath';
import { toast } from 'react-toastify';
import { setAddContactUs, onErrorStopLoad } from '../../slices/contactus';


// Worker saga will be fired on USER_FETCH_REQUESTED actions

function* addConatctUsForm(action) {
  try {
    const resp = yield call(ApiClient.post, action.url = ApiPath.ContactUsApiPath.ADD_CONTACT_US, action.payload = action.payload);
    if (resp.status) {
      yield put(setAddContactUs(resp.data.payload));
      yield call(action.payload.cb, action.res = resp)
      toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {
    yield put(setAddContactUs({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}


function* userContactUsSaga() {
  yield all([
    takeLatest('contactUs/addContactUs', addConatctUsForm),
  ])
}

export default userContactUsSaga;
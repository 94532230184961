import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    addSuggestion:{},
    loading: false
}

export const suggestionSlice = createSlice({
    name: 'resource',
    initialState,
    reducers: ({
        addSuggestion: (state) => {
            state.loading = true
        },
        setAddSuggestion: (state, action) => {
            state.loading = false
            state.addSuggestion = action.payload
        },
        onErrorStopLoad: (state) => {
            state.loading = false
        }
    }),
})

// Action creators are generated for each case reducer function
export const { addSuggestion, setAddSuggestion,  onErrorStopLoad } = suggestionSlice.actions

export default suggestionSlice.reducer